import snSessionsService from '../../services/snSessionsService'
import snContentService from '../../services/snContentService.js'
import snUserService from '../../services/snUserService'
import snServicerProfilesService from '../../services/snServicerProfilesService.js'
import _ from 'lodash'
import { query } from '../../services/graphqlService.js'
import autoLogoutMixin from '~/util/AutoLogoutMixin.js'
import gql from 'graphql-tag'

export default {
  mixins: [autoLogoutMixin],
  computed: {
    isLoggingIn () {
      if (location.pathname.includes('login')) return true
      else return false
    },
    isSearchingForLo () {
      if (location.pathname.includes('lo_not_found')) return true
      else return false
    },
    isSigningUp () {
      if (location.pathname.includes('signup') || location.pathname.includes('onboarding') || location.pathname.includes('go_mobile')) return true
      else return false
    }
  },
  async created () {
    if (!this.isSearchingForLo) {
      if (this.isSigningUp && !this.store.activationCode) {
        await this.getActivationCodeForSignup()
      } else if (this.isLoggingIn && !this.store.activationCode) {
        await this.getActivationCodeForLogin()
      } else {
        await this.getActivationCodeForPageLoad()
      }
    } else {
      this.sn_AutoLogoutMixin_setUpAutoLogout(this.borrowerLogoutUrl)
    }
    const params = new URLSearchParams(document.location.search.substring(1))
    this.store.inMobileApp = params.get('fromMobile') === 'true'
    this.store.inLoPreview = params.get('preview') === 'true'
  },

  methods: {
    borrowerLogoutUrl () {
      return `/borrower/login/${this.store.activationCode || ''}`
    },
    getServicerAndPartnerFromCode (code) {
      const params = `
      query getServicerAndPartnerFromCode ($code: String!) {
        public {
          servicer_activation_code(servicer_activation_code: $code) {
            code
          }
          company(servicer_activation_code: $code){
            show_company_legal_links
            feature_setting_allow_borrower_to_see_loans_with_multiple_los
            auth_flow_config_setting_share_link_email_verification
          }
          organization(servicer_activation_code: $code){
            sso_setting_borrower_sso_enabled
            state_lics {
              state_license_val
              state_license {
                state_code
              }
            }
            organization_setting_borrower_support_settings
            organization_setting_twilio_sms_enabled
          }
          servicer_profile(servicer_activation_code: $code) {
            id
            active
            name
            last_name
            full_name
            street1
            street2
            city
            state
            zip
            license
            short_url
            effective_state_codes
            state_lics {
              state_license_val
              state_license {
                state_code
              }
            }
            profile_url
            phone
            unformatted_phone
            office_unformatted_phone
            office_phone
            has_1003_enhanced
            company_has_data_sale
            allow_borrower_web_signup
            web_kiosk_enabled
            kiosk_loan_app_redirect_url
            effective_app_icon_url
            effective_company_logo_url
            effective_company_support_hours
            effective_company_support_phone
            effective_support_email
            effective_company_name
            effective_company_nmls
            effectiveEhLogos {
              id
              typeId
              name
              iconUrl
              iconName
              accountType
              ownerId
              ownerType
            }
            effective_website_disclaimer
            effective_privacy_policy_link
            effective_terms_of_use_link
            effective_allow_continue_skip_when_choosing_lo
            title
            auto_allow_partner_loan_access
            email
            office_phone
            website
            consumer_access_link
            has_contextual_chat:feature_setting_contextual_chat
            has_contextual_chat_allowed:feature_setting_contextual_chat_allowed
            feature_setting_use_loan_context_for_borrower
            feature_setting_borrower_dashboard_v2_enabled
            feature_setting_borrower_task_ui_refresh_enabled
            feature_setting_loan_app_address_autocomplete_enabled
            feature_setting_borrower_1003_v2_enabled
            feature_setting_force_mobile_web_signup_from_sharelink_enabled
            feature_setting_share_borrower_info_consent
            feature_setting_custom_consent_checkbox
            feature_setting_show_long_footer
            feature_setting_borrower_home_page_refresh
            feature_setting_generic_doc_uploads_enabled
            feature_setting_has_lo_1003_enabled
            feature_setting_loan_selection_v2_enabled
            feature_setting_ncino_privacy_policy
            feature_setting_borrower_loan_doc_view_enabled
            feature_setting_use_simplified_borrower_support_page
            feature_setting_show_loan_app_submitted_modal
            feature_setting_grant_loan_access_in_auth_flow_enabled
            feature_setting_force_heloc_doc_view
            feature_setting_verifications_1003_v2_enabled
            user_loan_app_setting_voa_enabled
            user_loan_app_setting_voie_enabled
            user_loan_app_setting_trid_enabled
            user_loan_app_setting_loan_type_purchase_enabled
            user_loan_app_setting_loan_type_refinance_enabled
            user_loan_app_setting_loan_type_cash_out_refinance_enabled
            user_loan_app_setting_loan_type_construction_enabled
            user_loan_app_setting_loan_type_heloc_enabled
            user_loan_app_setting_loan_type_home_equity_loan_enabled
            user_loan_app_setting_heloc_gmi_visibility
            user_loan_app_setting_construction_gmi_visibility
            user_loan_app_setting_allow_loan_app_edit
            user_loan_app_setting_display_charm
            user_loan_app_setting_credit_auth_text
            borrower_task_setting_insurance_provider_progressive_enabled
            borrower_task_setting_insurance_provider_hippo_enabled
          }
          partner(servicer_activation_code: $code) {
            id
            profile_img_url
            name
            last_name
            full_name
          }
        }
      }
      `
      const variables = {
        code: code
      }
      return query(params, variables)
    },
    async getInformationFromCodeAndSetUpStore (code) {
      try {
        const { data: { content } } = await snContentService.borrowerWebContent(code)
        if (content) this.store.borrowerWebContent = JSON.parse(content)
        const initialDataResponse = await this.getServicerAndPartnerFromCode(code)
        const { data: { public: { company, servicer_activation_code: codeObject, servicer_profile: servicerProfile, partner, organization: parentOrganization } } } = initialDataResponse
        this.setUpStore({ company: company, codeObject: codeObject, servicerProfile: servicerProfile, partner: partner, parentOrganization: parentOrganization })
      } catch (err) {
        console.log(err)
      }
    },
    loadUser () {
      return new Promise(function (resolve, reject) {
        snUserService.show()
          .then((response) => {
            if (response.data.user !== undefined) {
              const currentUser = JSON.parse(response.data.user)
              resolve(currentUser)
            }
          })
          .catch((e) => {
            reject(e)
          })
      }).catch(error => {
        console.log(error)
      })
    },
    async getActivationCodeForSignup () {
      let givenCode = ''
      const pathArray = location.pathname.split('/')
      pathArray.shift()
      if (pathArray[2] || pathArray[3]) { // e.g. .../signup/asmith1@banknorth.com
        givenCode = pathArray[pathArray.length - 1]
        this.sn_AutoLogoutMixin_setUpAutoLogout(this.borrowerLogoutUrl)
        this.store.activationCode = givenCode
        this.getInformationFromCodeAndSetUpStore(givenCode)
      } else {
        this.sn_AutoLogoutMixin_setUpAutoLogout(this.borrowerLogoutUrl)
        // Get borrower web content to be able to display logos on login page.
        this.store.support.hours = '7 a.m. - 6 p.m. MT'
        this.store.support.phone = '855.684.2777'
        this.store.support.email = 'mortgagehelp@ncino.com'
        const { data: { content } } = await snContentService.borrowerWebContent('')
        if (content) this.store.borrowerWebContent = JSON.parse(content)
      }
    },
    async getActivationCodeForLogin () {
      const pathArray = location.pathname.split('/')
      pathArray.shift()
      if (pathArray[2] || pathArray[3]) { // e.g. .../login/asmith1@banknorth.com
        const givenCode = pathArray[pathArray.length - 1]
        this.sn_AutoLogoutMixin_setUpAutoLogout(this.borrowerLogoutUrl)
        this.store.servicerEmail = givenCode
        this.getInformationFromCodeAndSetUpStore(givenCode)
      }
    },
    async getActivationCodeForPageLoad () {
      this.sn_AutoLogoutMixin_setUpAutoLogout(this.borrowerLogoutUrl)
      try {
        let givenCode = ''
        if (this.store.activationCode === null) {
          let servicerCode = null
          try {
            if (this.currentUser) servicerCode = await this.getCurrentUserServicerCode()
            if (servicerCode) givenCode = servicerCode
            this.store.activationCode = givenCode
          } catch (err) {
            console.log(err)
          }
          if (!servicerCode) {
            const response = await snSessionsService.getSAC()
            if (response.data.session_sac) {
              const codeObject = JSON.parse(response.data.session_sac)
              givenCode = codeObject.code
              this.store.activationCode = givenCode
            } else if (this.$route.params.code) {
              givenCode = this.$route.params.code
              this.store.activationCode = givenCode
            } else {
              const pathArray = location.pathname.split('/')
              pathArray.shift()
              if (pathArray[2] || pathArray[3]) { // e.g. .../signup/asmith1@banknorth.com || /login/asmith1@banknorth.com
                givenCode = pathArray[pathArray.length - 1]
                this.store.activationCode = givenCode
              }
            }
          }
        } else {
          givenCode = this.store.activationCode
        }
        this.getInformationFromCodeAndSetUpStore(givenCode)
      } catch (err) {
        console.log(err)
      }
    },
    async getCurrentUserServicerCode () {
      const currentUser = await this.loadUser()
      return new Promise(resolve => {
        this.$apollo.query({
          query: gql`
          query ($appUserGuid: ID!) {
            app_user (guid: $appUserGuid) {
              id
              servicer_activation_code {
                servicer_profile_id
                code
              }
            }
          }
        `,
          variables: {
            appUserGuid: currentUser.app_user.guid
          }
        }).then(({ data }) => {
          const validCode = data.app_user.servicer_activation_code.code
          resolve(validCode)
        }).catch(error => {
          console.log(error)
        })
      })
    },
    getiPhoneMockup ({ partnerId = '', servicerId = '' }) {
      // Currently, graphql expects servicer id as a string type...
      return this.$axios.get(`/servicer_share/iphone_mockup/${servicerId}/${partnerId}`)
    },
    setUpStore ({ company, codeObject, servicerProfile, partner, parentOrganization }) {
      if (codeObject && codeObject.code) this.store.activationCode = codeObject.code
      if (company) this.store.allowBorrowerToSeeLoansWithMultipleLos = company.feature_setting_allow_borrower_to_see_loans_with_multiple_los
      if (servicerProfile) {
        this.store.servicer = _.cloneDeep(servicerProfile)
        this.store.servicer.company = _.cloneDeep(company)
        this.store.servicer.parentOrganization = _.cloneDeep(parentOrganization)
        // The following few lines are returned as JSONs for the time being
        if (this.store.servicer.parentOrganization.state_lics) {
          this.store.servicer.parentOrganization.state_lics = this.store.servicer.parentOrganization.state_lics.map(sl => {
            return { state: sl.state_license.state_code, value: sl.state_license_val }
          })
        }
        if (this.store.servicer.state_lics) {
          this.store.servicer.state_lics = this.store.servicer.state_lics.map(sl => {
            return { state: sl.state_license.state_code, value: sl.state_license_val }
          })
        }
        if (this.store.servicer.state_lics.length === 0 && this.store.servicer.parentOrganization && this.store.servicer.parentOrganization.state_lics) this.store.servicer.state_lics = this.store.servicer.parentOrganization.state_lics
        const borrowerSupport = JSON.parse(parentOrganization.organization_setting_borrower_support_settings?.replaceAll('=>', ':'))
        this.store.support.hours = borrowerSupport.support_hours_message
        this.store.support.email = borrowerSupport.support_email
        this.store.support.phone = borrowerSupport.support_phone
        this.store.logoUrl = servicerProfile.effective_company_logo_url
        this.store.iconUrl = servicerProfile.effective_app_icon_url
        this.store.servicer.allowContinueSkipWhenChoosingLo = servicerProfile.effective_allow_continue_skip_when_choosing_lo
      }
      if (partner) this.store.partner = _.cloneDeep(partner)
      const partnerId = partner ? partner.id : ''
      const servicerId = servicerProfile ? servicerProfile.id : ''
      if (partnerId !== '' || servicerId !== '') {
        this.getiPhoneMockup({ partnerId: partnerId, servicerId: servicerId }).then(({ data: { mockup } }) => {
          if (mockup) this.store.mockup = mockup
        }).catch(
          (error) => {
            console.log(error)
          }
        )
      }
      this.getAdditionalInformationFromApi()
      if (this.store.hasTwoFactorAuthed === undefined || this.store.hasTwoFactorAuthed === null) {
        this.store.hasTwoFactorAuthed = false
      }
    },
    getAdditionalInformationFromApi () {
      snServicerProfilesService.getServicerProfile(this.store.activationCode).then(result => {
        const { data: { links, servicer_link_types: servicerLinkTypes } } = result
        this.store.servicerLinks = links
        this.store.servicerLinkTypes = servicerLinkTypes
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
